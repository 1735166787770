import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NgSelectizeModule } from 'ng-selectize';

// Base
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { BaseComponent } from './base/base.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';

// List and overall views
import { BorrowsComponent } from './borrows/borrows.component';
import { DocumentsComponent } from './documents/documents.component';
import { ItemsComponent } from './items/items.component';
import { ProfileComponent } from './profile/profile.component';
import { SettingsComponent } from './settings/settings.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { UsersComponent } from './users/users.component';
import { SystemAdminComponent } from './systemadmin/systemadmin.component';
import { BarcodeComponent } from './barcode/barcode.component';

// Specific views
import { BorrowComponent } from './borrow/borrow.component';
import { AudioComponent } from './audio/audio.component';
import { BookComponent } from './book/book.component';
import { PressComponent } from './press/press.component';
import { VideoComponent } from './video/video.component';
import { UserProfileComponent } from './userprofile/userprofile.component';

// Add / Modify Views
import { AddModifyBookComponent } from './addmodify-book/addmodify-book.component';
import { AddModifyAudioComponent } from './addmodify-audio/addmodify-audio.component';
import { AddModifyVideoComponent } from './addmodify-video/addmodify-video.component';
import { AddModifyPressComponent } from './addmodify-press/addmodify-press.component';
import { AddModifyBorrowComponent } from './addmodify-borrow/addmodify-borrow.component';
import { AddModifyUserComponent } from './addmodify-user/addmodify-user.component';
import { MassBorrowComponent } from './massborrow/massborrow.component';

import { AutofocusDirective } from './autofocus';
//import { ChartsModule } from 'ng2-charts';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    BaseComponent,
    LoginComponent,
    HomeComponent,
    BorrowsComponent,
    DocumentsComponent,
    ItemsComponent,
    ProfileComponent,
    SettingsComponent,
    StatisticsComponent,
    UsersComponent,
    SystemAdminComponent,
    BarcodeComponent,

    BorrowComponent,
    AudioComponent,
    BookComponent,
    PressComponent,
    VideoComponent,
    UserProfileComponent,

    AddModifyBookComponent,
    AddModifyAudioComponent,
    AddModifyVideoComponent,
    AddModifyPressComponent,
    AddModifyBorrowComponent,
    AddModifyUserComponent,
    MassBorrowComponent,

    AutofocusDirective
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'museion' }),
    HttpClientModule,
    FormsModule,
    NgSelectizeModule,
    //ChartsModule,
    RouterModule.forRoot([
      { path: '', component: BaseComponent, pathMatch: 'full' },
      { path: 'login', component: LoginComponent },
      { path: 'login/:code', component: LoginComponent },
      { path: 'home', component: HomeComponent },
      { path: 'borrows', component: BorrowsComponent },
      { path: 'borrows/:status', component: BorrowsComponent },
      { path: 'documents', component: DocumentsComponent },
      { path: 'items', component: ItemsComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'settings', component: SettingsComponent },
      { path: 'statistics', component: StatisticsComponent },
      { path: 'users', component: UsersComponent },
      { path: 'sadmin', component: SystemAdminComponent },
      { path: 'barcode', component: BarcodeComponent },

      { path: 'borrow/:id', component: BorrowComponent },
      { path: 'audio/:id', component: AudioComponent },
      { path: 'book/:id', component: BookComponent },
      { path: 'press/:id', component: PressComponent },
      { path: 'video/:id', component: VideoComponent },
      { path: 'userprofile/:id', component: UserProfileComponent },

      { path: 'ambook', component: AddModifyBookComponent },
      { path: 'ambook/:id', component: AddModifyBookComponent },
      { path: 'amaudio', component: AddModifyAudioComponent },
      { path: 'amaudio/:id', component: AddModifyAudioComponent },
      { path: 'amvideo', component: AddModifyVideoComponent },
      { path: 'amvideo/:id', component: AddModifyVideoComponent },
      { path: 'ampress', component: AddModifyPressComponent },
      { path: 'ampress/:id', component: AddModifyPressComponent },
      { path: 'massborrow/:itemid', component: MassBorrowComponent },
      { path: 'amborrow', component: AddModifyBorrowComponent },
      { path: 'amborrow/:id', component: AddModifyBorrowComponent },
      { path: 'amborrow/:userid/:itemid/:status', component: AddModifyBorrowComponent },
      { path: 'amborrow/:userid/:itemid/:copyid/:status', component: AddModifyBorrowComponent },
      { path: 'amuser', component: AddModifyUserComponent },
      { path: 'amuser/:id', component: AddModifyUserComponent }
    ])
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
