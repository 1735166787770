import { Component, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import * as jwt_decode from "jwt-decode";

@Component({
  selector: 'app-barcode',
  templateUrl: './barcode.component.html',
})
export class BarcodeComponent {
  public baseurl: string;
  public message: string;
  public messagetype: number;
  public messageid: number;
  public success: boolean;
  public search: string;
  public isAdmin: boolean;
  public autoConfirm: boolean;
  public useExactCopies: boolean;
  public checkIdentity: number;
  public settings: Settings;
  public timeout: any;

  constructor(@Inject('BASE_URL') baseUrl: string, private httpService: HttpClient, private router: Router ) {
    this.baseurl = baseUrl;
    this.message = "";
    this.messagetype = 0;
    this.messageid = 0;
    this.isAdmin = false;
    this.autoConfirm = true;
    this.useExactCopies = true;
    this.checkIdentity = 0;
    this.search = "";

    let token = this.getDecodedAccessToken(localStorage.getItem('jwt'));
    for (let i = 0; i < token.roles.length; i++) {
      if (token.roles[i] === 'Admin')
        this.isAdmin = true;
    }

    let params = new HttpParams().set('token', localStorage.getItem('jwt'));
    this.httpService.get<Settings>(this.baseurl + 'api/Settings/GetLibrarySettings', { params: params }).subscribe(result => {
      this.settings = result;
      this.useExactCopies = result.useExactCopies;
    },
      error => { });

  }

  handleBarcode() {
    this.search = this.search.replace('ö', '0');
    var root = this;
    clearTimeout(this.timeout);

    this.timeout = setTimeout(function () {
      if (root.search != undefined) {
        root.processBarcode(root.search);
      }
    }, 500);
  }

  processBarcode(barcode: string) {
    this.messagetype = 0;
    this.message = "";
    let params = new HttpParams().set('token', localStorage.getItem('jwt')).set('search', barcode).set('isCopies', this.useExactCopies.toString());
    this.httpService.get<BarcodeResult[]>(this.baseurl + 'api/Barcode/Search', { params: params }).subscribe(result => {
      this.search = "";
      if (result.length === 0) {
        this.message = "A kért azonosító nem található!";
        this.messagetype = 1;
      }

      if (result.length > 1) {
        this.message = "A kért azonosító többször is szerepel a rendszerben";
        this.messagetype = 1;
      }

      if (result.length === 1) {
        if (result[0].go === 'GoToBook') {
          this.router.navigate(['book', result[0].id]);
        }
        if (result[0].go === 'GoToAudio') {
          this.router.navigate(['audio', result[0].id]);
        }
        if (result[0].go === 'GoToPress') {
          this.router.navigate(['press', result[0].id]);
        }
        if (result[0].go === 'GoToVideo') {
          this.router.navigate(['video', result[0].id]);
        }
        if (result[0].go === 'GetBackBorrow') {
          this.getBackBorrow(result[0].id);
        }
        if (result[0].go === 'BorrowReserved') {
          this.getBorrowReserved(result[0].id);
        }
        if (result[0].go === 'BorrowSignedup') {
          this.getBorrowSignedup(result[0].id);
        }
      }

    },
      error => {
      });
  }

  getBackBorrow(id: number) {
    this.messageid = id;
    if (this.autoConfirm) {
      this.processBorrow();
      this.message = "Kikölcsönzött könyv visszahozva!";
      this.messagetype = 2;
    }
    else {
      this.messagetype = 3;
    }
  }

  getBorrowReserved(id: number) {
    this.messageid = id;
    if (this.autoConfirm) {
      this.processReserved();
      this.message = "Lefoglalt könyv kikölcsönözve!";
      this.messagetype = 2;
    }
    else {
      this.messagetype = 4;
    }
  }

  getBorrowSignedup(id: number) {
    this.messageid = id;
    if (this.autoConfirm) {
      this.processPreBorrow();
      this.message = "Előjegyzett könyv kikölcsönözve!";
      this.messagetype = 2;
    }
    else {
      this.messagetype = 5;
    }
  }

  backTo() {
    this.messagetype = 0;
  }

  processBorrow() {
    let params = new HttpParams().set('token', localStorage.getItem('jwt')).set('borrowId', this.messageid.toString());
    this.httpService.get<boolean>(this.baseurl + 'api/Borrows/FinishBorrow', { params: params }).subscribe(result => {
      this.message = "Kikölcsönzött könyv visszahozva!";
      this.messagetype = 2;
    },
      error => {
        this.message = "Ismeretlen hiba történt!";
        this.success = false;
      });
  }

  processReserved() {
    let params = new HttpParams().set('token', localStorage.getItem('jwt')).set('borrowId', this.messageid.toString());
    this.httpService.get<boolean>(this.baseurl + 'api/Borrows/SaveBorrowForBookFromPreBorrow', { params: params }).subscribe(result => {
      this.message = "Lefoglalt könyv kikölcsönözve!";
      this.messagetype = 2;
    },
      error => {
        this.success = false;
      });
  }

  processPreBorrow() {
    let params = new HttpParams().set('token', localStorage.getItem('jwt')).set('borrowId', this.messageid.toString());
    this.httpService.get<boolean>(this.baseurl + 'api/Borrows/SaveBorrowForBookFromPreBorrow', { params: params }).subscribe(result => {
      this.message = "Előjegyzett könyv kikölcsönözve!";
      this.messagetype = 2;
    },
      error => {
        this.success = false;
      });
  }
  
  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }
}

interface BarcodeResult {
  id: number;
  go: string;
}

interface Settings {
  useExactCopies: boolean;
  allowPrebooking: boolean;
  borrowPeriod: number;
}
