import { Component, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NgSelectizeModule } from 'ng-selectize';
import * as jwt_decode from "jwt-decode";

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
})
export class VideoComponent {
  public baseurl: string;
  public isModify: boolean;
  public itemId: string;
  public item: Item;
  public copies: Copy[];
  public message: string;
  public success: boolean;
  public sub: any;
  public isAdmin: boolean;
  public settings: Settings;
  public startBooking: boolean;
  public userList: User[];
  public bookingUserId: string;
  public bookingCopyId: string;
  public finishDate: string;
  public showDate: boolean;
  public bookingState: string;
  public borrows: Borrow[];
  public requiredDropdownConfig: any = REQUIRED_DROPDOWN_CONFIG;

  constructor(@Inject('BASE_URL') baseUrl: string, private route: ActivatedRoute, private httpService: HttpClient, private datePipe: DatePipe, private router: Router) {
    this.baseurl = baseUrl;
    this.message = "";
    this.isModify = false;
    this.itemId = "";
    this.isAdmin = false;
    this.startBooking = true;
    this.bookingCopyId = "";
    this.bookingUserId = "";
    this.bookingState = "3";
    this.showDate = true;

    let token = this.getDecodedAccessToken(localStorage.getItem('jwt'));
    for (let i = 0; i < token.roles.length; i++) {
      if (token.roles[i] === 'Admin')
        this.isAdmin = true;
    }

    this.sub = this.route.params.subscribe(params => {
      this.itemId = params['id'];
    });

    if (this.itemId !== "" && this.itemId !== undefined) {
      this.isModify = true;
    }

    if (this.isModify) {
      let params = new HttpParams().set('token', localStorage.getItem('jwt')).set('itemId', this.itemId);
      this.httpService.get<Item>(this.baseurl + 'api/Items/GetVideo', { params: params }).subscribe(result => {
        this.item = result;
      },
        error => {
          this.message = "Ismeretlen hiba történt!";
          this.success = false;
        });

      this.httpService.get<Copy[]>(this.baseurl + 'api/Items/GetCopiesForItem', { params: params }).subscribe(result => {
        this.copies = result;
      },
        error => {
          this.message = "Ismeretlen hiba történt!";
          this.success = false;
        });

      this.httpService.get<Settings>(this.baseurl + 'api/Settings/GetLibrarySettings', { params: params }).subscribe(result => {
        this.settings = result;
        if (!result.useExactCopies)
          this.bookingCopyId = "0";
        var yourDate = new Date(Date.now() + 1000 * 60 * 60 * 24 * result.borrowPeriod);
        this.finishDate = datePipe.transform(yourDate, 'yyyy-MM-dd');
      },
        error => { });

      params = new HttpParams()
        .set('token', localStorage.getItem('jwt'))
        .set('itemId', this.itemId);
      this.httpService.get<Borrow[]>(this.baseurl + 'api/Borrows/GetAllBorrowsByItem', { params: params }).subscribe(result => {
        this.borrows = result;
      },
        error => {
          this.message = "Ismeretlen hiba történt!";
          this.success = false;
        });
      this.bookingLoad();
    }
    else {
      this.router.navigate(['items']);
    }
  }

  changeBookingState() {
    if (this.bookingState === "3")
      this.showDate = true;
    else
      this.showDate = false;
  }

  removeBook() {
    this.item.onDelete = true;
  }

  deleteBook() {
    let params = new HttpParams().set('token', localStorage.getItem('jwt')).set('itemId', this.item.itemId.toString());
    this.httpService.get<boolean>(this.baseurl + 'api/Items/DeleteItem', { params: params }).subscribe(result => {
      if (result)
        this.router.navigate(['items']);
    },
      error => {
        this.message = "Ismeretlen hiba történt!";
        this.success = false;
      });
  }

  bookingLoad() {
    let params = new HttpParams().set('token', localStorage.getItem('jwt'));
    this.httpService.get<User[]>(this.baseurl + 'api/Users/GetUsersForBooking', { params: params }).subscribe(result => {
      this.userList = result;
      this.startBooking = true;
    },
      error => {
        this.message = "Ismeretlen hiba történt!";
        this.success = false;
      });
  }

  saveBooking() {
    if (this.bookingUserId !== "") {
      let params = new HttpParams()
        .set('token', localStorage.getItem('jwt'))
        .set('itemid', this.itemId)
        .set('bookerid', this.bookingUserId)
        .set('copyid', this.bookingCopyId)
        .set('state', this.bookingState)
        .set('finish', this.finishDate);
      this.httpService.get<boolean>(this.baseurl + 'api/Borrows/SaveBorrowForBook', { params: params }).subscribe(result => {
        if (result) {

          params = new HttpParams().set('token', localStorage.getItem('jwt')).set('itemId', this.itemId);
          this.httpService.get<Item>(this.baseurl + 'api/Items/GetVideo', { params: params }).subscribe(result => {
            this.item = result;
          },
            error => {
              this.message = "Ismeretlen hiba történt!";
              this.success = false;
            });

          this.httpService.get<Copy[]>(this.baseurl + 'api/Items/GetCopiesForItem', { params: params }).subscribe(result => {
            this.copies = result;
            this.bookingCopyId = "";
            this.bookingUserId = "";
            this.bookingState = "3";
            this.showDate = true;
            this.startBooking = false;
          },
            error => {
              this.message = "Ismeretlen hiba történt!";
              this.success = false;
            });

          params = new HttpParams()
            .set('token', localStorage.getItem('jwt'))
            .set('itemId', this.itemId);
          this.httpService.get<Borrow[]>(this.baseurl + 'api/Borrows/GetAllBorrowsByItem', { params: params }).subscribe(result => {
            this.borrows = result;
          },
            error => {
              this.message = "Ismeretlen hiba történt!";
              this.success = false;
            });
        }
        else {
          this.message = "Ismeretlen hiba történt!";
          this.success = false;
        }

      },
        error => {
          this.message = "Ismeretlen hiba történt!";
          this.success = false;
        });
    }
    else {
      this.message = "Ismeretlen hiba történt!";
      this.success = false;
    }
    
  }

  savePreBooking() {
    let params = new HttpParams()
      .set('token', localStorage.getItem('jwt'))
      .set('itemid', this.itemId);
    this.httpService.get<boolean>(this.baseurl + 'api/Borrows/SavePreBorrowForBook', { params: params }).subscribe(result => {
      if (result) {
        this.router.navigate(['borrows']);
      }
      else {
        this.message = "Ismeretlen hiba történt!";
        this.success = false;
      }

    },
      error => {
        this.message = "Ismeretlen hiba történt!";
        this.success = false;
      });

  }

  finishBorrow(id: number) {
    for (let i = 0; i < this.borrows.length; i++) {
      if (id === this.borrows[i].borrowId) {
        this.borrows[i].onFinish = true;
      }
    }
  }

  finishBorrowConfirm(id: number) {
    for (let i = 0; i < this.borrows.length; i++) {
      if (id === this.borrows[i].borrowId) {
        let params = new HttpParams().set('token', localStorage.getItem('jwt')).set('borrowId', this.borrows[i].borrowId.toString());
        this.httpService.get<boolean>(this.baseurl + 'api/Borrows/FinishBorrow', { params: params }).subscribe(result => {

          let params = new HttpParams()
            .set('token', localStorage.getItem('jwt'))
            .set('itemId', this.itemId);
          this.httpService.get<Borrow[]>(this.baseurl + 'api/Borrows/GetAllBorrowsByItem', { params: params }).subscribe(result => {
            this.borrows = result;
          },
            error => {
              this.message = "Ismeretlen hiba történt!";
              this.success = false;
            });

        },
          error => {
            this.message = "Ismeretlen hiba történt!";
            this.success = false;
          });
      }
    }
  }

  deleteBorrow(id: number) {
    for (let i = 0; i < this.borrows.length; i++) {
      if (id === this.borrows[i].borrowId) {
        this.borrows[i].onDelete = true;
      }
    }
  }

  deleteBorrowConfirm(id: number) {
    for (let i = 0; i < this.borrows.length; i++) {
      if (id === this.borrows[i].borrowId) {
        let params = new HttpParams().set('token', localStorage.getItem('jwt')).set('borrowId', this.borrows[i].borrowId.toString());
        this.httpService.get<boolean>(this.baseurl + 'api/Borrows/DeleteBorrow', { params: params }).subscribe(result => {

          let params = new HttpParams()
            .set('token', localStorage.getItem('jwt'))
            .set('itemId', this.itemId);
          this.httpService.get<Borrow[]>(this.baseurl + 'api/Borrows/GetAllBorrowsByItem', { params: params }).subscribe(result => {
            this.borrows = result;
          },
            error => {
              this.message = "Ismeretlen hiba történt!";
              this.success = false;
            });

        },
          error => {
            this.message = "Ismeretlen hiba történt!";
            this.success = false;
          });
      }
    }
  }

  onPreBorrowBorrow(id: number) {
    for (let i = 0; i < this.borrows.length; i++) {
      if (id === this.borrows[i].borrowId) {
        this.borrows[i].onPreBorrow = true;
      }
    }
  }

  onPreBorrowBorrowConfirm(id: number) {
    for (let i = 0; i < this.borrows.length; i++) {
      if (id === this.borrows[i].borrowId) {
        let params = new HttpParams().set('token', localStorage.getItem('jwt')).set('borrowId', this.borrows[i].borrowId.toString());
        this.httpService.get<boolean>(this.baseurl + 'api/Borrows/SaveBorrowForBookFromPreBorrow', { params: params }).subscribe(result => {

          let params = new HttpParams()
            .set('token', localStorage.getItem('jwt'))
            .set('itemId', this.itemId);
          this.httpService.get<Borrow[]>(this.baseurl + 'api/Borrows/GetAllBorrowsByItem', { params: params }).subscribe(result => {
            this.borrows = result;
          },
            error => {
              this.message = "Ismeretlen hiba történt!";
              this.success = false;
            });

        },
          error => {
            this.message = "Ismeretlen hiba történt!";
            this.success = false;
          });
      }
    }
  }

  createRange(number) {
    var items: number[] = [];
    for (var i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }
}

interface Item {
  itemId: number;
  itemType: string;
  identifier: string;
  identifier2: string;
  title: string;
  author: string;
  cover: string;

  subtitle: string;
  description: string;
  languageId: number;
  actors: string;
  writers: string;
  castCrew: string;
  borrowability: number;
  length: number;
  imdbLink: string;
  videoType: number;
  location: string;
  translator: string;

  allPieces: number;
  availablePieces: number;
  onDelete: boolean;
}

interface Copy {
  num: number;
  copyId: string;
  itemId: string;
  identifier: string;
  description: string;
  status: number;
}

interface Settings {
  useExactCopies: boolean;
  allowPrebooking: boolean;
  borrowPeriod: number;
}

interface User {
  value: number;
  text: string;
}

interface Borrow {
  borrowId: number;
  itemId: number;
  userId: number;
  copyId: number;
  title: string;
  author: string;
  cover: string;
  start: string;
  projectedEnd: string;
  status: number;
  userName: string;
  onFinish: boolean;
  onPreBorrow: boolean;
  onDelete: boolean;
}

import { Validators, AbstractControl } from '@angular/forms';

export const startWithR = (control: AbstractControl): any => {
  return control.value && control.value.toLowerCase().startsWith('r') ? null : { doesntStartWithR: true };
};

export const REQUIRED_DROPDOWN_CONFIG = {
  highlight: false,
  create: false,
  persist: true,
  plugins: ['dropdown_direction', 'remove_button'],
  dropdownDirection: 'down',
  labelField: 'text',
  valueField: 'value',
  searchField: ['text', 'value'],
  maxItems: 1
};
