import { Component, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import * as jwt_decode from "jwt-decode";
import { b } from '@angular/core/src/render3';

@Component({
  selector: 'app-systemadmin',
  templateUrl: './systemadmin.component.html',
})
export class SystemAdminComponent {
  public baseurl: string;
  public items: Institut[];
  public message: string;
  public success: boolean;
  public isSuperAdmin: boolean;
  public newInstitut: boolean;
  public searchOC: boolean;
  public searchType: string;
  public searchName: string;

  public niCode: string;
  public niName: string;
  public niAdminName: string;
  public niAdminPass: string;
  public niType: string;

  constructor(@Inject('BASE_URL') baseUrl: string, private httpService: HttpClient, ) {
    this.baseurl = baseUrl;
    this.message = "";
    this.isSuperAdmin = false;
    this.searchType = "-1";
    this.searchName = "";
    this.searchOC = false;
    this.newInstitut = false;

    let token = this.getDecodedAccessToken(localStorage.getItem('jwt'));
    for (let i = 0; i < token.roles.length; i++) {
      if (token.roles[i] === 'SuperAdmin')
        this.isSuperAdmin = true;
    }

    if (!this.isSuperAdmin)
      window.location.href = baseUrl + 'home/';

    let params = new HttpParams()
      .set('token', localStorage.getItem('jwt'))
      .set('name', this.searchName)
      .set('type', this.searchType);
    this.httpService.get<Institut[]>(this.baseurl + 'api/Admin/GetInstituts', { params: params }).subscribe(result => {
      this.items = result;
    },
      error => {
        this.message = "Ismeretlen hiba történt!";
        this.success = false;
      });
  }

  searchOpenClose() {
    if (this.searchOC)
      this.searchOC = false;
    else
      this.searchOC = true;
  }

  newItemOpenClose() {
    if (this.newInstitut)
      this.newInstitut = false;
    else {
      this.newInstitut = true;
      this.niType = "0";
      this.niCode = "";
      this.niName = "";
      this.niAdminName = "admin";
      this.niAdminPass = "";
    }
  }

  onSearch() {
    let params = new HttpParams()
      .set('token', localStorage.getItem('jwt'))
      .set('name', this.searchName)
      .set('type', this.searchType);
    this.httpService.get<Institut[]>(this.baseurl + 'api/Admin/GetInstituts', { params: params }).subscribe(result => {
      this.items = result;
    },
      error => {
        this.message = "Ismeretlen hiba történt!";
        this.success = false;
      });
  }

  saveNewInstitut() {
    let params = new HttpParams()
      .set('token', localStorage.getItem('jwt'))
      .set('code', this.niCode)
      .set('name', this.niName)
      .set('adminname', this.niAdminName)
      .set('adminpass', this.niAdminPass)
      .set('type', this.niType);
    this.httpService.get<boolean>(this.baseurl + 'api/Admin/CreateInstitut', { params: params }).subscribe(result => {
      if (result) {
        this.newInstitut = false;
        params = new HttpParams()
          .set('token', localStorage.getItem('jwt'))
          .set('name', this.searchName)
          .set('type', this.searchType);
        this.httpService.get<Institut[]>(this.baseurl + 'api/Admin/GetInstituts', { params: params }).subscribe(result => {
          this.items = result;
        },
          error => {
            this.message = "Ismeretlen hiba történt!";
            this.success = false;
          });
      }
    },
      error => {
        this.message = "Ismeretlen hiba történt!";
        this.success = false;
      });
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }
}

interface Institut {
  institutId: number;
  museionCode: string;
  shortName: string;
  longName: string;
  type: number;
  users: number;
  items: number;
  borrows: number;
}
