import { Component, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import * as jwt_decode from "jwt-decode";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-massborrow',
  templateUrl: './massborrow.component.html',
})
export class MassBorrowComponent {
  public baseurl: string;
  public users: User[];
  public itemId: number;
  public message: string;
  public success: boolean;
  public isAdmin: boolean;
  public searchFullName: string;
  public searchGroup: string;
  public finishDate: string;
  public showDate: boolean;
  public bookingState: string;
  public settings: Settings;
  public allborrowed: number[];
  public item: Item;
  public sub: any;

  constructor(@Inject('BASE_URL') baseUrl: string, private route: ActivatedRoute, private httpService: HttpClient, private datePipe: DatePipe, private router: Router) {
    this.baseurl = baseUrl;
    this.message = "";
    this.isAdmin = false;
    this.bookingState = "3";
    this.showDate = true;
    this.searchFullName = "";
    this.searchGroup = "";
    this.allborrowed = [];

    let token = this.getDecodedAccessToken(localStorage.getItem('jwt'));
    for (let i = 0; i < token.roles.length; i++) {
      if (token.roles[i] === 'Admin')
        this.isAdmin = true;
    }

    this.sub = this.route.params.subscribe(params => {
      this.itemId = params['itemid'];
    });

    let params = new HttpParams().set('token', localStorage.getItem('jwt'));
    this.httpService.get<Settings>(this.baseurl + 'api/Settings/GetLibrarySettings', { params: params }).subscribe(result => {
      this.settings = result;
      var yourDate = new Date(Date.now() + 1000 * 60 * 60 * 24 * result.borrowPeriod);
      this.finishDate = datePipe.transform(yourDate, 'yyyy-MM-dd');
    },
      error => { });

    params = new HttpParams().set('token', localStorage.getItem('jwt')).set('itemId', this.itemId.toString());
    this.httpService.get<Item>(this.baseurl + 'api/Items/GetItem', { params: params }).subscribe(result => {
      this.item = result;
    },
      error => { });

    this.onSearch();
  }

  onSearch() {
    let params = new HttpParams().set('token', localStorage.getItem('jwt')).set('fullName', this.searchFullName).set('group', this.searchGroup);
    this.httpService.get<User[]>(this.baseurl + 'api/Users/GetUsers', { params: params }).subscribe(result => {
      this.users = result;
      this.checkAllBorrowed();
    },
      error => {
        this.message = "Ismeretlen hiba történt!";
        this.success = false;
      });
  }

  changeBookingState() {
    if (this.bookingState === "3")
      this.showDate = true;
    else
      this.showDate = false;
  }

  borrow(user: number) {
    let params = new HttpParams()
      .set('token', localStorage.getItem('jwt'))
      .set('itemid', this.itemId.toString())
      .set('bookerid', user.toString())
      .set('copyid', "0")
      .set('state', this.bookingState)
      .set('finish', this.finishDate);
    this.httpService.get<boolean>(this.baseurl + 'api/Borrows/SaveBorrowForBook', { params: params }).subscribe(result => {
      if (result) {
        this.allborrowed.push(user);
        this.checkAllBorrowed();
      }
      else {
        this.message = "Ismeretlen hiba történt!";
        this.success = false;
      }

    },
      error => {
        this.message = "Ismeretlen hiba történt!";
        this.success = false;
      });   
  }

  checkAllBorrowed() {
    for (let i = 0; i < this.users.length; i++) {
      let status = false;
      for (let j = 0; j < this.allborrowed.length; j++) {
        if (this.allborrowed[j] === this.users[i].userId)
          status = true;
      }
      this.users[i].borrowed = status;
    }
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }
}

interface User {
  userId: number;
  loginName: string;
  fullName: string;
  password: string;
  birth: string;
  identifier: string;
  type: number;
  group: string;
  borrowed: boolean;
}

interface Item {
  itemId: string;
  itemType: string;
  identifier: string;
  identifier2: string;
  title: string;
  author: string;
}

interface Settings {
  useExactCopies: boolean;
  allowPrebooking: boolean;
  borrowPeriod: number;
}
