import { Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
//import { Observable, Subscription } from 'rxjs';
//import { fromEvent } from 'rxjs';
//import 'rxjs/add/observable/fromEvent';
import * as jwt_decode from "jwt-decode";

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  isExpanded = false;
  usertype: string;
  showMenu: boolean;
  url: string;
  isAdmin: boolean;
  public index: Index;
  //subscription: Subscription;

  constructor(private httpService: HttpClient, @Inject('BASE_URL') baseUrl: string, @Inject(DOCUMENT) private document: Document, private router: Router) {
    this.url = encodeURI(baseUrl);
    this.usertype = "";
    this.isAdmin = false;

    this.showMenu = true;
    if (this.document.location.href.toLowerCase() == baseUrl.toLowerCase()) {
      this.showMenu = false;
      if (localStorage.getItem('jwt') == undefined)
        window.location.href = baseUrl + 'login/';
    }
    else if (this.document.location.href.toLowerCase().indexOf('/login') !== -1) {
      this.showMenu = false;
    }
    else {
      if (localStorage.getItem('jwt') == undefined)
        window.location.href = baseUrl + 'login/';

      let token = this.getDecodedAccessToken(localStorage.getItem('jwt'));
      for (let i = 0; i < token.roles.length; i++) {
        if (token.roles[i] === 'Admin')
          this.isAdmin = true;
      }
      
    }

  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }

  tobarcode() {
    this.router.navigate(['barcode']);
  }

  logout() {
    localStorage.clear();
    window.location.href = this.url + 'login/';
  }

  ngOnInit() {
    //this.subscription = fromEvent(document, 'keypress').subscribe(e => {
    //  console.log(e);
    //})
  }

  ngOnDestroy() {
    //this.subscription.unsubscribe();
  }
}

interface Index {
  baseuserid: string;
  baseusertype: string;
}
