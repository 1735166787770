import { Component, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import * as jwt_decode from "jwt-decode";

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
})
export class ItemsComponent {
  public baseurl: string;
  public items: Item[];
  public message: string;
  public success: boolean;
  public isAdmin: boolean;
  public userId: number;
  public searchOC: boolean;
  public addOC: boolean;
  public searchTitle: string;
  public searchAuthor: string;
  public searchSubtitle: string;
  public searchIsbn: string;
  public searchIdentifier: string;
  public searchType: string;
  public searchAvailable: boolean;
  public orderby: string;

  constructor(@Inject('BASE_URL') baseUrl: string, private httpService: HttpClient, private router: Router ) {
    this.baseurl = baseUrl;
    this.message = "";
    this.isAdmin = false;
    this.searchOC = false;
    this.addOC = false;

    this.searchTitle = "";
    this.searchAuthor = "";
    this.searchSubtitle = "";
    this.searchIsbn = "";
    this.searchIdentifier = "";
    this.searchType = "0";
    this.searchAvailable = false;
    this.orderby = "titleasc";


    let token = this.getDecodedAccessToken(localStorage.getItem('jwt'));
    for (let i = 0; i < token.roles.length; i++) {
      if (token.roles[i] === 'Admin')
        this.isAdmin = true;
    }
    this.userId = token.id;

    let params = new HttpParams()
      .set('token', localStorage.getItem('jwt'))
      .set('title', this.searchTitle)
      .set('author', this.searchAuthor)
      .set('subtitle', this.searchSubtitle)
      .set('isbn', this.searchIsbn)
      .set('identifier', this.searchIdentifier)
      .set('type', this.searchType)
      .set('available', this.searchAvailable.toString())
      .set('orderby', this.orderby);
    this.httpService.get<Item[]>(this.baseurl + 'api/Items/GetItems', { params: params }).subscribe(result => {
      this.items = result;
    },
      error => {
        this.message = "Ismeretlen hiba történt!";
        this.success = false;
      });

  }

  changeOrder(o: string) {
    this.orderby = o;
    this.onSearch();
  }
  searchOpenClose() {
    if (this.searchOC)
      this.searchOC = false;
    else
      this.searchOC = true;
  }

  addOpenClose() {
    if (this.addOC)
      this.addOC = false;
    else
      this.addOC = true;
  }

  onSearch() {
    let params = new HttpParams()
      .set('token', localStorage.getItem('jwt'))
      .set('title', this.searchTitle)
      .set('author', this.searchAuthor)
      .set('subtitle', this.searchSubtitle)
      .set('isbn', this.searchIsbn)
      .set('identifier', this.searchIdentifier)
      .set('type', this.searchType)
      .set('available', this.searchAvailable.toString())
      .set('orderby', this.orderby);
    this.httpService.get<Item[]>(this.baseurl + 'api/Items/GetItems', { params: params }).subscribe(result => {
      this.items = result;
    },
      error => {
        this.message = "Ismeretlen hiba történt!";
        this.success = false;
      });
  }

  removeBook(id: number) {
    for (let i = 0; i < this.items.length; i++) {
      if (id === this.items[i].itemId) {
        this.items[i].onDelete = true;
      }
    }
  }

  deleteBook(id: number) {
    for (let i = 0; i < this.items.length; i++) {
      if (id === this.items[i].itemId) {
        let params = new HttpParams().set('token', localStorage.getItem('jwt')).set('itemId', this.items[i].itemId.toString());
        this.httpService.get<boolean>(this.baseurl + 'api/Items/DeleteItem', { params: params }).subscribe(result => {
          if (result) {
            let params = new HttpParams()
              .set('token', localStorage.getItem('jwt'))
              .set('title', this.searchTitle)
              .set('author', this.searchAuthor)
              .set('subtitle', this.searchSubtitle)
              .set('isbn', this.searchIsbn)
              .set('identifier', this.searchIdentifier)
              .set('type', this.searchType)
              .set('available', this.searchAvailable.toString())
              .set('orderby', this.orderby);
            this.httpService.get<Item[]>(this.baseurl + 'api/Items/GetItems', { params: params }).subscribe(result => {
              this.items = result;
            },
              error => {
                this.message = "Ismeretlen hiba történt!";
                this.success = false;
              });
          }
        },
          error => {
            this.message = "Ismeretlen hiba történt!";
            this.success = false;
          });
      }
    }
  }

  prebookBook(id: number) {
    for (let i = 0; i < this.items.length; i++) {
      if (id === this.items[i].itemId) {
        this.items[i].onPreBooking = true;
      }
    }
  }

  savePreBooking(id: number) {
    let params = new HttpParams()
      .set('token', localStorage.getItem('jwt'))
      .set('itemid', id.toString());
    this.httpService.get<boolean>(this.baseurl + 'api/Borrows/SavePreBorrowForBook', { params: params }).subscribe(result => {
      if (result) {
        window.location.href = this.baseurl + 'borrows';
      }
      else {
        this.message = "Ismeretlen hiba történt!";
        this.success = false;
      }

    },
      error => {
        this.message = "Ismeretlen hiba történt!";
        this.success = false;
      });
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }
}

interface Item {
  itemId: number;
  itemType: string;
  identifier: string;
  identifier2: string;
  title: string;
  author: string;
  cover: string;
  allPieces: number;
  availablePieces: number;
  onDelete: boolean;
  onPreBooking: boolean;
}
