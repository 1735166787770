import { Component, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import * as jwt_decode from "jwt-decode";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
})
export class ProfileComponent {
  public baseurl: string;
  public oldpassword: string;
  public password: string;
  public passwordagain: string;
  public message: string;
  public success: boolean;

  constructor(@Inject('BASE_URL') baseUrl: string, private httpService: HttpClient, private router: Router) {
    this.baseurl = baseUrl;
    this.message = "";
  } 

  onPasswordChange() {
    this.message = "";
    this.success = true;
    if (this.password == undefined || this.oldpassword == undefined || this.passwordagain == undefined) {
      this.message = "Kérjük adja meg a szükséges adatokat!";
      this.success = false;
    }
    else {
      if (this.password == this.passwordagain) {
        let token = this.getDecodedAccessToken(localStorage.getItem('jwt'));
        let params = new HttpParams().set('token', localStorage.getItem('jwt')).set('username', token.name).set('password', this.password).set('oldpassword', this.oldpassword);
        this.httpService.get<boolean>(this.baseurl + 'api/Profile/PasswordChange', { params: params }).subscribe(result => {
          if (result) {
            this.message = "A jelszó sikeresen megváltozott!";
            this.success = true;
          }
          else {
            this.message = "A megadott jelszó nem megfelelő!";
            this.success = false;
          }
        },
          error => {
            this.message = "Ismeretlen hiba történt!";
            this.success = false;
          });
      }
      else {
        this.message = "A megadott jelszavak nem egyeznek meg!";
        this.success = false;
      }
    }
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }
}
