import { Component, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import * as jwt_decode from "jwt-decode";

@Component({
  selector: 'app-userprofile',
  templateUrl: './userprofile.component.html',
})
export class UserProfileComponent {
  public baseurl: string;
  public isModify: boolean;
  public isAdmin: boolean;
  public userId: string;
  public user: User;
  public message: string;
  public success: boolean;
  public sub: any;

  constructor(@Inject('BASE_URL') baseUrl: string, private route: ActivatedRoute, private httpService: HttpClient, private router: Router) {
    this.baseurl = baseUrl;
    this.message = "";
    this.isModify = false;
    this.userId = "";
    this.isAdmin = false;

    let token = this.getDecodedAccessToken(localStorage.getItem('jwt'));
    for (let i = 0; i < token.roles.length; i++) {
      if (token.roles[i] === 'Admin')
        this.isAdmin = true;
    }

    this.sub = this.route.params.subscribe(params => {
      this.userId = params['id'];
    });

    if (this.userId !== "" && this.userId !== undefined) {
      this.isModify = true;
    }

    if (!this.isModify) {
      this.router.navigate(['users']);
    }

    let params = new HttpParams().set('token', localStorage.getItem('jwt')).set('userId', this.userId);
    this.httpService.get<User>(this.baseurl + 'api/Users/GetUserForModify', { params: params }).subscribe(result => {
      this.user = result;
    },
      error => {
        this.message = "Ismeretlen hiba történt!";
        this.success = false;
      });
  }

  deleteUser(id: number) {
    this.user.onDelete = true;
  }

  deleteUserConfirm(id: number) {
    let params = new HttpParams().set('token', localStorage.getItem('jwt')).set('userId', this.userId.toString());
    this.httpService.get<boolean>(this.baseurl + 'api/Users/RemoveUser', { params: params }).subscribe(result => {
      this.router.navigate(['users']);
    },
      error => {
        this.message = "Ismeretlen hiba történt!";
        this.success = false;
      });
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }
}

interface User {
  userId: string;
  userType: string;
  userName: string;
  password: string;
  fullName: string;
  firstName: string;
  lastName: string;
  titleName: string;
  motherName: string;
  cityOfBirth: string;
  dateOfBirth: string;
  identifier: string;
  email: string;
  group: string;
  onDelete: boolean;
}

