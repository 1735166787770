import { Component, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import * as jwt_decode from "jwt-decode";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent {
  public baseurl: string;
  public items: Item[];
  public message: string;
  public success: boolean;
  public isAdmin: boolean;
  public search: string;
  public isSearchResult: boolean;
  public searchResult: SearchResult[];
  public preorders: Borrow[];

  constructor(@Inject('BASE_URL') baseUrl: string, private httpService: HttpClient, private router: Router ) {
    this.baseurl = baseUrl;
    this.message = "";
    this.isAdmin = false;

    this.search = "";
    this.isSearchResult = false;

    let token = this.getDecodedAccessToken(localStorage.getItem('jwt'));
    for (let i = 0; i < token.roles.length; i++) {
      if (token.roles[i] === 'Admin')
        this.isAdmin = true;
    }

    if (this.isAdmin) {
      let params = new HttpParams().set('token', localStorage.getItem('jwt'));
      this.httpService.get<Item[]>(this.baseurl + 'api/Home/GetLateBorrows', { params: params }).subscribe(result => {
        this.items = result;
      },
        error => {
          this.message = "Ismeretlen hiba történt!";
          this.success = false;
        });
    }
    else {
      let params = new HttpParams().set('token', localStorage.getItem('jwt'));
      this.httpService.get<Item[]>(this.baseurl + 'api/Home/GetMyLateBorrows', { params: params }).subscribe(result => {
        this.items = result;
      },
        error => {
          this.message = "Ismeretlen hiba történt!";
          this.success = false;
        });
    }

    let params = new HttpParams()
      .set('token', localStorage.getItem('jwt'))
      .set('isAdmin', this.isAdmin.toString());
    this.httpService.get<Borrow[]>(this.baseurl + 'api/Borrows/GetPreordersForHome', { params: params }).subscribe(result => {
      this.preorders = result;
    },
      error => {
        this.message = "Ismeretlen hiba történt!";
        this.success = false;
      });

  }

  onSearch() {
    let params = new HttpParams().set('token', localStorage.getItem('jwt')).set('search', this.search).set('isAdmin', this.isAdmin ? "1" : "0");
    this.httpService.get<SearchResult[]>(this.baseurl + 'api/Home/Search', { params: params }).subscribe(result => {
      this.isSearchResult = true;
      this.searchResult = result;
      if (result.length == 1) {
        this.goSearchResult(1);
      }
      
    },
      error => {
      });
    
  }

  goSearchResult(searchnum: number) {
    for (let i = 0; i < this.searchResult.length; i++) {
      if (searchnum == this.searchResult[i].num) {
        if (this.searchResult[i].itemType == 'Book')
          this.router.navigate(['book', this.searchResult[i].itemId]);
        if (this.searchResult[i].itemType == 'Audio')
          this.router.navigate(['audio', this.searchResult[i].itemId]);
        if (this.searchResult[i].itemType == 'Video')
          this.router.navigate(['video', this.searchResult[i].itemId]);
        if (this.searchResult[i].itemType == 'Press')
          this.router.navigate(['press', this.searchResult[i].itemId]);
        if (this.searchResult[i].itemType == 'User')
          this.router.navigate(['userprofile', this.searchResult[i].itemId]);
        if (this.searchResult[i].itemType == 'Copy')
          this.router.navigate(['item', this.searchResult[i].itemId]);
        if (this.searchResult[i].itemType == 'Books')
          this.router.navigate(['items']);
        if (this.searchResult[i].itemType == 'Users')
          this.router.navigate(['users']);
        if (this.searchResult[i].itemType == 'Copies')
          this.router.navigate(['items']);
      }
    }
  }

  deleteBorrow(id: number) {
    for (let i = 0; i < this.preorders.length; i++) {
      if (id === this.preorders[i].borrowId) {
        this.preorders[i].onDelete = true;
      }
    }
  }

  deleteBorrowConfirm(id: number) {
    for (let i = 0; i < this.preorders.length; i++) {
      if (id === this.preorders[i].borrowId) {
        let params = new HttpParams().set('token', localStorage.getItem('jwt')).set('borrowId', this.preorders[i].borrowId.toString());
        this.httpService.get<boolean>(this.baseurl + 'api/Borrows/DeleteBorrow', { params: params }).subscribe(result => {

          let params = new HttpParams()
            .set('token', localStorage.getItem('jwt'))
            .set('isAdmin', this.isAdmin.toString());
          this.httpService.get<Borrow[]>(this.baseurl + 'api/Borrows/GetPreordersForHome', { params: params }).subscribe(result => {
            this.preorders = result;
          },
            error => {
              this.message = "Ismeretlen hiba történt!";
              this.success = false;
            });

        },
          error => {
            this.message = "Ismeretlen hiba történt!";
            this.success = false;
          });
      }
    }
  }

  onPreBorrowBorrow(id: number) {
    for (let i = 0; i < this.preorders.length; i++) {
      if (id === this.preorders[i].borrowId) {
        this.preorders[i].onPreBorrow = true;
      }
    }
  }

  onPreBorrowBorrowConfirm(id: number) {
    for (let i = 0; i < this.preorders.length; i++) {
      if (id === this.preorders[i].borrowId) {
        let params = new HttpParams().set('token', localStorage.getItem('jwt')).set('borrowId', this.preorders[i].borrowId.toString());
        this.httpService.get<boolean>(this.baseurl + 'api/Borrows/SaveBorrowForBookFromPreBorrow', { params: params }).subscribe(result => {

          let params = new HttpParams()
            .set('token', localStorage.getItem('jwt'))
            .set('isAdmin', this.isAdmin.toString());
          this.httpService.get<Borrow[]>(this.baseurl + 'api/Borrows/GetPreordersForHome', { params: params }).subscribe(result => {
            this.preorders = result;
          },
            error => {
              this.message = "Ismeretlen hiba történt!";
              this.success = false;
            });

        },
          error => {
            this.message = "Ismeretlen hiba történt!";
            this.success = false;
          });
      }
    }
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }
}

interface Item {
  itemId: string;
  itemType: string;
  title: string;
  author: string;
  cover: string;
  lateSince: string;
  userName: string;
}

interface SearchResult {
  num: number;
  itemId: number;
  itemType: string;
  itemTypeName: string;
  itemTypeNumber: number;
  itemName: string;
  itemValueName: string;
}

interface Borrow {
  borrowId: number;
  itemId: number;
  userId: number;
  copyId: number;
  itemType: number;
  title: string;
  author: string;
  cover: string;
  start: string;
  projectedEnd: string;
  status: number;
  userName: string;
  onFinish: boolean;
  onPreBorrow: boolean;
  onDelete: boolean;
}
