import { Component, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { NgSelectizeModule } from 'ng-selectize';
import { DatePipe } from '@angular/common';
import * as jwt_decode from "jwt-decode";

@Component({
  selector: 'app-addmodify-borrow',
  templateUrl: './addmodify-borrow.component.html',
})
export class AddModifyBorrowComponent {
  public baseurl: string;
  public isModify: boolean;
  public borrowId: string;
  public borrow: Borrow;
  public message: string;
  public success: boolean;
  public sub: any;
  public showDate: boolean;
  public settings: Settings;
  public userList: User[];
  public copies: Copy[];
  public item: Item;
  public bookingUserId: string;

  constructor(@Inject('BASE_URL') baseUrl: string, private route: ActivatedRoute, private httpService: HttpClient, private datePipe: DatePipe, private router: Router) {
    this.baseurl = baseUrl;
    this.message = "";
    this.isModify = false;
    this.borrowId = "";
    this.showDate = false;
    this.bookingUserId = "";

    this.sub = this.route.params.subscribe(params => {
      this.borrowId = params['id'];
    });

    if (this.borrowId !== "" && this.borrowId !== undefined) {
      this.isModify = true;
    }

    let params = new HttpParams().set('token', localStorage.getItem('jwt'));
    this.httpService.get<User[]>(this.baseurl + 'api/Users/GetUsersForBooking', { params: params }).subscribe(result => {
      this.userList = result;
    },
      error => {
        this.message = "Ismeretlen hiba történt!";
        this.success = false;
      });

    this.httpService.get<Settings>(this.baseurl + 'api/Settings/GetLibrarySettings', { params: params }).subscribe(result => {
      this.settings = result;
    },
      error => { });

    if (this.isModify) {
      params = new HttpParams().set('token', localStorage.getItem('jwt')).set('borrowId', this.borrowId);
      this.httpService.get<Borrow>(this.baseurl + 'api/Borrows/GetBorrow', { params: params }).subscribe(result => {
        this.borrow = result;
        this.bookingUserId = result.userId.toString();
        this.borrow.start = datePipe.transform(this.borrow.startDate, 'yyyy-MM-dd')
        this.borrow.projectedEnd = datePipe.transform(this.borrow.projectedEndDate, 'yyyy-MM-dd')
        this.changeBookingState();
      },
        error => {
          this.message = "Ismeretlen hiba történt!";
          this.success = false;
        });
    }
    else {
      params = new HttpParams().set('token', localStorage.getItem('jwt'));
      this.httpService.get<Borrow>(this.baseurl + 'api/Borrows/GetSampleBorrow', { params: params }).subscribe(result => {
        this.borrow = result;
      },
        error => {
          this.message = "Ismeretlen hiba történt!";
          this.success = false;
        });
    }
  }

  changeBookingState() {
    if (this.borrow.status === 3)
      this.showDate = true;
    else
      this.showDate = false;
  }

  changeItem() {
    if (this.settings.useExactCopies) {
      let params = new HttpParams().set('token', localStorage.getItem('jwt')).set('itemId', this.borrow.itemId.toString());
      this.httpService.get<Copy[]>(this.baseurl + 'api/Items/GetCopiesForItem', { params: params }).subscribe(result => {
        this.copies = result;
      },
        error => {
          this.message = "Ismeretlen hiba történt!";
          this.success = false;
        });
    }
  }

  onSave() {
    let res = false;
    this.borrow.userId = +this.bookingUserId;
    this.borrow.startDate = this.borrow.start;
    this.borrow.projectedEndDate = this.borrow.projectedEnd;
    if (this.isModify) {
      let params = new HttpParams()
        .set('token', localStorage.getItem('jwt'))
        .set('borrowId', this.borrowId)
        .set('borrow', JSON.stringify(this.borrow));
      this.httpService.get<boolean>(this.baseurl + 'api/Borrows/SaveBorrow', { params: params }).subscribe(result => {
        res = result;
        this.router.navigate(['borrows']);
      }, error => { this.message = "Ismeretlen hiba történt!"; this.success = false; });
    }
    else {
      let params = new HttpParams()
        .set('token', localStorage.getItem('jwt'))
        .set('borrow', JSON.stringify(this.borrow));
      this.httpService.get<boolean>(this.baseurl + 'api/Borrows/SaveNewBorrow', { params: params }).subscribe(result => {
        res = result;
        this.router.navigate(['borrows']);
      }, error => { this.message = "Ismeretlen hiba történt!"; this.success = false; });
    }
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }
}

interface Borrow {
  borrowId: number;
  itemId: number;
  userId: number;
  copyId: number;
  title: string;
  author: string;
  cover: string;
  start: string;
  projectedEnd: string;
  startDate: string;
  projectedEndDate: string;
  status: number;
  userName: string;
  onFinish: boolean;
}

interface User {
  value: number;
  text: string;
}

interface Settings {
  useExactCopies: boolean;
  allowPrebooking: boolean;
  borrowPeriod: number;
}

interface Copy {
  num: number;
  copyId: string;
  itemId: string;
  identifier: string;
  description: string;
  status: number;
}

interface Item {
  itemId: string;
  itemType: string;
  identifier: string;
  identifier2: string;
  title: string;
  author: string;
}

export const REQUIRED_DROPDOWN_CONFIG = {
  highlight: false,
  create: false,
  persist: true,
  plugins: ['dropdown_direction', 'remove_button'],
  dropdownDirection: 'down',
  labelField: 'text',
  valueField: 'value',
  searchField: ['text', 'value'],
  maxItems: 1
};
