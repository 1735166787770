import { Component, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
//import { ChartDataSets, ChartOptions } from 'chart.js';
//import { Color, Label } from 'ng2-charts';
import * as jwt_decode from "jwt-decode";

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
})
export class StatisticsComponent {
  public baseurl: string;
  public message: string;
  public success: boolean;
  public sub: any;
  public baseStat: BaseStat;

  constructor(@Inject('BASE_URL') baseUrl: string, private route: ActivatedRoute, private httpService: HttpClient, private router: Router) {
    this.baseurl = baseUrl;
    this.message = "";


    let params = new HttpParams().set('token', localStorage.getItem('jwt'));
    this.httpService.get<BaseStat>(this.baseurl + 'api/Statistics/GetBaseStatistics', { params: params }).subscribe(result => {
      this.baseStat = result;
    },
      error => {
        this.message = "Ismeretlen hiba történt!";
        this.success = false;
      });
     
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }
}

interface BaseStat {
  numofBooks: number;
  numofCopies: number;
  numofUsers: number;
  numofStudents: number;
  numofNonStudents: number;
}


