import { Component, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import * as jwt_decode from "jwt-decode";

@Component({
  selector: 'app-addmodify-user',
  templateUrl: './addmodify-user.component.html',
})
export class AddModifyUserComponent {
  public baseurl: string;
  public isModify: boolean;
  public userId: string;
  public user: User;
  public message: string;
  public success: boolean;
  public sub: any;

  constructor(@Inject('BASE_URL') baseUrl: string, private route: ActivatedRoute, private httpService: HttpClient, private router: Router) {
    this.baseurl = baseUrl;
    this.message = "";
    this.isModify = false;
    this.userId = "";

    this.sub = this.route.params.subscribe(params => {
      this.userId = params['id'];
    });

    if (this.userId !== "" && this.userId !== undefined) {
      this.isModify = true;
    }

    if (this.isModify) {
      let params = new HttpParams().set('token', localStorage.getItem('jwt')).set('userId', this.userId);
      this.httpService.get<User>(this.baseurl + 'api/Users/GetUserForModify', { params: params }).subscribe(result => {
        this.user = result;
      },
        error => {
          this.message = "Ismeretlen hiba történt!";
          this.success = false;
        });
    }
    else {
      this.httpService.get<User>(this.baseurl + 'api/Users/GetSampleUser').subscribe(result => {
        this.user = result;
      },
        error => {
          this.message = "Ismeretlen hiba történt!";
          this.success = false;
        });
    }

  }

  onSave() {
    let token = localStorage.getItem('jwt');
    let res = false;
    if (this.isModify) {
      let data = {
        "token": token,
        "userId": this.userId,
        "user": JSON.stringify(this.user)
      };
      let head = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      this.httpService.post<boolean>(this.baseurl + 'api/Users/SaveUser', JSON.stringify(data), { headers: head }).subscribe(result => {
        res = result;
        this.router.navigate(['users']);
      }, error => { this.message = "Ismeretlen hiba történt!"; this.success = false; });
    }
    else {
      let data = {
        "token": token,
        "userId": "0",
        "user": JSON.stringify(this.user)
      };
      let head = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      this.httpService.post<boolean>(this.baseurl + 'api/Users/SaveNewUser', JSON.stringify(data), { headers: head }).subscribe(result => {
        res = result;
        this.router.navigate(['users']);
      }, error => { this.message = "Ismeretlen hiba történt!"; this.success = false; });
    } 
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }
}

interface User {
  userId: string;
  userType: string;
  userName: string;
  password: string;
  fullName: string;
  firstName: string;
  lastName: string;
  titleName: string;
  motherName: string;
  cityOfBirth: string;
  dateOfBirth: string;
  identifier: string;
  email: string;
  group: string;
}
