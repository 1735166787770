import { Component, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { OnInit, Output, EventEmitter } from '@angular/core';
import { HttpEventType } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import * as jwt_decode from "jwt-decode";
import { downloadFile } from 'file-saver';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
})
export class SettingsComponent {
  public baseurl: string;
  public oldpassword: string;
  public password: string;
  public passwordagain: string;
  public message: string;
  public success: boolean;
  public institut: Institut;
  public settings: Settings;
  public userimport: any;
  public progress: number;
  public tabname: string;
  @Output() public onUploadFinished = new EventEmitter();

  constructor(@Inject('BASE_URL') baseUrl: string, private httpService: HttpClient, private router: Router ) {
    this.baseurl = baseUrl;
    this.message = "";
    this.tabname = "base";

    this.getSettings();
    this.getInstitut();
  }

  getSettings() {
    let params = new HttpParams().set('token', localStorage.getItem('jwt'));
    this.httpService.get<Settings>(this.baseurl + 'api/Settings/GetLibrarySettings', { params: params }).subscribe(result => {
      this.settings = result;
    },
      error => {
        this.message = "Ismeretlen hiba történt!";
        this.success = false;
      });
  }

  onSettingsChange() {
    let params = new HttpParams().set('token', localStorage.getItem('jwt'))
      .set('borrowPeriod', this.settings.borrowPeriod.toString())
      .set('gracePeriod', this.settings.gracePeriod.toString())
      .set('delayPeriod', this.settings.delayPeriod.toString())
      .set('useExactCopies', this.settings.useExactCopies.toString())
      .set('allowPrebooking', this.settings.allowPrebooking.toString());
    this.httpService.get<Settings>(this.baseurl + 'api/Settings/UpdateLibrarySettings', { params: params }).subscribe(result => {
      this.settings = result;
      this.message = "Sikeres mentés!";
      this.success = true;
      this.getSettings();
    },
      error => {
        this.message = "Ismeretlen hiba történt!";
        this.success = false;
      });
  }

  getInstitut() {
    let params = new HttpParams().set('token', localStorage.getItem('jwt'));
    this.httpService.get<Institut>(this.baseurl + 'api/Settings/GetInstitut', { params: params }).subscribe(result => {
      this.institut = result;
    },
      error => {
        this.message = "Ismeretlen hiba történt!";
        this.success = false;
      });
  }

  onInstitutChange() {
    let params = new HttpParams().set('token', localStorage.getItem('jwt'))
      .set('shortName', this.institut.shortName.toString())
      .set('longName', this.institut.longName.toString())
      .set('identifier', this.institut.identifier.toString())
      .set('identifierMinistry', this.institut.identifierMinistry.toString());
    this.httpService.get<number>(this.baseurl + 'api/Settings/UpdateInstitut', { params: params }).subscribe(result => {
      this.message = "Sikeres mentés!";
      this.success = true;
      this.getInstitut();
    },
      error => {
        this.message = "Ismeretlen hiba történt!";
        this.success = false;
      });
  }

  public importBooks = (files) => {
    if (files.length === 0) {
      return;
    }

    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('token', localStorage.getItem('jwt'));
    formData.append('file', fileToUpload, fileToUpload.name);

    this.httpService.post(this.baseurl + 'api/Settings/ImportBooks', formData, { reportProgress: true, observe: 'events' })
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          this.router.navigate(['items']);
          this.onUploadFinished.emit(event.body);
        }
      });
  }

  public importBooksWithCopies = (files) => {
    if (files.length === 0) {
      return;
    }

    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('token', localStorage.getItem('jwt'));
    formData.append('file', fileToUpload, fileToUpload.name);

    this.httpService.post(this.baseurl + 'api/Settings/ImportBooksWithCopies', formData, { reportProgress: true, observe: 'events' })
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          this.router.navigate(['items']);
          this.onUploadFinished.emit(event.body);
        }
      });
  }

  public importAudios = (files) => {
    if (files.length === 0) {
      return;
    }

    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('token', localStorage.getItem('jwt'));
    formData.append('file', fileToUpload, fileToUpload.name);

    this.httpService.post(this.baseurl + 'api/Settings/ImportAudios', formData, { reportProgress: true, observe: 'events' })
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          this.router.navigate(['items']);
          this.onUploadFinished.emit(event.body);
        }
      });
  }

  public importVideos = (files) => {
    if (files.length === 0) {
      return;
    }

    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('token', localStorage.getItem('jwt'));
    formData.append('file', fileToUpload, fileToUpload.name);

    this.httpService.post(this.baseurl + 'api/Settings/ImportVideos', formData, { reportProgress: true, observe: 'events' })
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          this.router.navigate(['items']);
          this.onUploadFinished.emit(event.body);
        }
      });
  }

  public importPress = (files) => {
    if (files.length === 0) {
      return;
    }

    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('token', localStorage.getItem('jwt'));
    formData.append('file', fileToUpload, fileToUpload.name);

    this.httpService.post(this.baseurl + 'api/Settings/ImportPress', formData, { reportProgress: true, observe: 'events' })
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          this.router.navigate(['items']);
          this.onUploadFinished.emit(event.body);
        }
      });
  }

  public importUsers = (files) => {
    if (files.length === 0) {
      return;
    }

    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('token', localStorage.getItem('jwt'));
    formData.append('file', fileToUpload, fileToUpload.name);

    this.httpService.post(this.baseurl + 'api/Settings/ImportUsers', formData, { reportProgress: true, observe: 'events' })
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          this.router.navigate(['users']);
          this.onUploadFinished.emit(event.body);
        }
      });
  }

  public importUsersWithClass = (files) => {
    if (files.length === 0) {
      return;
    }

    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('token', localStorage.getItem('jwt'));
    formData.append('file', fileToUpload, fileToUpload.name);

    this.httpService.post(this.baseurl + 'api/Settings/ImportUsersWithClass', formData, { reportProgress: true, observe: 'events' })
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          this.router.navigate(['users']);
          this.onUploadFinished.emit(event.body);
        }
      });
  }

  public importBorrows = (files) => {
    if (files.length === 0) {
      return;
    }

    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('token', localStorage.getItem('jwt'));
    formData.append('file', fileToUpload, fileToUpload.name);

    this.httpService.post(this.baseurl + 'api/Settings/ImportBorrows', formData, { reportProgress: true, observe: 'events' })
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          this.router.navigate(['borrows']);
          this.onUploadFinished.emit(event.body);
        }
      });
  }

  public switchTab(tap: string) {
    this.tabname = tap;
  }

  public downloadFile() {

    let params = new HttpParams().set('token', localStorage.getItem('jwt'));
    this.httpService.get<any>(this.baseurl + 'api/Export/UserExport').subscribe(result => {
      const blob = new Blob(result, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    },
      error => {
        console.log(error);
        this.message = "Ismeretlen hiba történt!";
        this.success = false;
      });


  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }
}

interface Settings {
  borrowPeriod: number;
  gracePeriod: number;
  delayPeriod: number;
  useExactCopies: boolean;
  allowPrebooking: boolean;
}

interface Institut {
  institutId: number;
  museionCode: string;
  shortName: string;
  longName: string;
  identifier: string;
  identifierMinistry: string;
}
