import { Component, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import * as jwt_decode from "jwt-decode";

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
})
export class LoginComponent {
  public baseurl: string;
  public libraryId: string;
  public code: string;
  public loginname: string;
  public password: string;
  public err: boolean;
  public libraries: Library[];
  public type: number;
  private sub: any;

  constructor(@Inject('BASE_URL') baseUrl: string, private httpService: HttpClient, private route: ActivatedRoute) {
    this.baseurl = baseUrl;
    this.err = false;
    this.code = "";
    this.type = 0;

    this.sub = this.route.params.subscribe(params => {
      this.code = params['code'];
    });

    this.libraryId = '';
    this.loginname = '';
    this.password = '';

    let params = new HttpParams().set('type', this.type.toString());
    this.httpService.get<Library[]>(this.baseurl + 'api/Login/GetInstituts', { params: params }).subscribe(result => {
      this.libraries = result;
      if (this.code !== "") {
        for (let i = 0; i < this.libraries.length; i++) {
          if (this.libraries[i].LibraryCode === this.code)
            this.libraryId = this.libraries[i].LibraryId;
        }
      }
    },
      error => {console.error(error)});
  }

  onLogin() {
    this.err = false;
    let jwt = "";
    let params = new HttpParams().set('libraryId', this.libraryId).set('username', this.loginname).set('password', this.password);
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    this.httpService.get<string>(this.baseurl + 'api/Login/Login', { headers: headers, params: params }).subscribe(result => {
      if (result == "") {
        this.err = true;
      }
      else {
        localStorage.setItem('jwt', result);
        let token = this.getDecodedAccessToken(result);
        window.location.href = this.baseurl + 'home/';
      }
    },
    error => {
      console.error(error)
    });
  }

  onLoginPost() {
    this.err = false;
    let jwt = "";
    let data = {
      "libraryId": this.libraryId,
      "loginname": this.loginname,
      "password": this.password
    };
    let head = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    this.httpService.post<string>(this.baseurl + 'api/Login/LoginPost', JSON.stringify(data), { headers: head }).subscribe(result => {
      if (result == "") {
        this.err = true;
      }
      else {
        localStorage.setItem('jwt', result);
        let token = this.getDecodedAccessToken(result);
        window.location.href = this.baseurl + 'home/';
      }
    }, error => {
      console.error(error)
    });

  }

  onSwitch() {
    let temptype = 0;
    if (this.type === 0) {
      temptype = 1;
    }
    else {
      temptype = 0;
    }
    this.code = "";
    let params = new HttpParams().set('type', temptype.toString());
    this.httpService.get<Library[]>(this.baseurl + 'api/Login/GetInstituts', { params: params }).subscribe(result => {
      this.libraries = result;
      this.type = temptype;
      this.sub = this.route.params.subscribe(params => {
        this.code = params['code'];
      });
      if (this.code !== "") {
        for (let i = 0; i < this.libraries.length; i++) {
          if (this.libraries[i].LibraryCode === this.code)
            this.libraryId = this.libraries[i].LibraryId;
        }
      }
    },
      error => { console.error(error) });
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }
}

interface Library {
  LibraryId: string;
  LibraryName: string;
  LibraryCode: string;
}
