import { Component, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import * as jwt_decode from "jwt-decode";

@Component({
  selector: 'app-addmodify-audio',
  templateUrl: './addmodify-audio.component.html',
})
export class AddModifyAudioComponent {
  public baseurl: string;
  public isModify: boolean;
  public itemId: string;
  public item: Item;
  public copies: Copy[];
  public message: string;
  public success: boolean;
  public sub: any;

  constructor(@Inject('BASE_URL') baseUrl: string, private route: ActivatedRoute, private httpService: HttpClient, private router: Router ) {
    this.baseurl = baseUrl;
    this.message = "";
    this.isModify = false;
    this.itemId = "";

    this.sub = this.route.params.subscribe(params => {
      this.itemId = params['id'];
    });

    if (this.itemId !== "" && this.itemId !== undefined) {
      this.isModify = true;
    }

    if (this.isModify) {
      let params = new HttpParams().set('token', localStorage.getItem('jwt')).set('itemId', this.itemId);
      this.httpService.get<Item>(this.baseurl + 'api/Items/GetAudioForModify', { params: params }).subscribe(result => {
        this.item = result;
      },
        error => {
          this.message = "Ismeretlen hiba történt!";
          this.success = false;
        });

      this.httpService.get<Copy[]>(this.baseurl + 'api/Items/GetCopiesForItem', { params: params }).subscribe(result => {
        this.copies = result;
      },
        error => {
          this.message = "Ismeretlen hiba történt!";
          this.success = false;
        });
    }
    else {
      this.httpService.get<Item>(this.baseurl + 'api/Items/GetSampleAudio').subscribe(result => {
        this.item = result;
      },
        error => {
          this.message = "Ismeretlen hiba történt!";
          this.success = false;
        });

      this.httpService.get<Copy[]>(this.baseurl + 'api/Items/GetSampleCopiesForItem').subscribe(result => {
        this.copies = result;
      },
        error => {
          this.message = "Ismeretlen hiba történt!";
          this.success = false;
        });
    }
  }

  onSave() {
    let token = localStorage.getItem('jwt');
    let res = false;
    if (this.isModify) {
      let data = {
        "token": token,
        "itemId": this.itemId,
        "item": JSON.stringify(this.item),
        "copies": JSON.stringify(this.copies)
      };
      let head = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      this.httpService.post<boolean>(this.baseurl + 'api/Items/SaveAudio', JSON.stringify(data), {headers : head}).subscribe(result => {
        res = result;
        this.router.navigate(['items']);
      }, error => { this.message = "Ismeretlen hiba történt!"; this.success = false; });
    }
    else {
      let data = {
        "token": token,
        "itemId": "0",
        "item": JSON.stringify(this.item),
        "copies": JSON.stringify(this.copies)
      };
      let head = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      this.httpService.post<boolean>(this.baseurl + 'api/Items/SaveNewAudio', JSON.stringify(data), { headers: head }).subscribe(result => {
        res = result;
        this.router.navigate(['items']);
      }, error => { this.message = "Ismeretlen hiba történt!"; this.success = false; });
    } 
  }

  onAddOtherCopy()
  {
    let copy = <Copy>JSON.parse("{\"num\": " + (this.copies.length + 1).toString() + ", \"copyId\": \"\", \"itemId\": \"\", \"identifier\": \"\", \"description\": \"\"}");
    this.copies.push(copy);
  }

  removeCopy(copynum: number) {
    for (let i = 0; i < this.copies.length; i++) {
      if (copynum == this.copies[i].num)
        this.copies.splice(i, 1);
    }
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }
}

interface Item {
  itemId: string;
  itemType: string;
  identifier: string;
  identifier2: string;
  title: string;
  performer: string;
  cover: string;
  subtitle: string;
  description: string;
  languageId: number;
  composers: string;
  writers: string;
  borrowability: number;
  length: number;
  audioType: number;
  location: string;
}

interface Copy {
  num: number;
  copyId: string;
  itemId: string;
  identifier: string;
  description: string;
}

