import { Component, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import * as jwt_decode from "jwt-decode";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
})
export class UsersComponent {
  public baseurl: string;
  public users: User[];
  public message: string;
  public success: boolean;
  public isAdmin: boolean;
  public searchOC: boolean;
  public searchFullName: string;
  public searchGroup: string;
  public orderby: string;

  constructor(@Inject('BASE_URL') baseUrl: string, private httpService: HttpClient, private router: Router ) {
    this.baseurl = baseUrl;
    this.message = "";
    this.isAdmin = false;
    this.searchOC = false;
    this.searchFullName = "";
    this.searchGroup = "";
    this.orderby = "userasc";

    let token = this.getDecodedAccessToken(localStorage.getItem('jwt'));
    for (let i = 0; i < token.roles.length; i++) {
      if (token.roles[i] === 'Admin')
        this.isAdmin = true;
    }

    this.onSearch();
  }

  onSearch() {
    let params = new HttpParams().set('token', localStorage.getItem('jwt')).set('fullName', this.searchFullName).set('group', this.searchGroup).set('orderby', this.orderby);
    this.httpService.get<User[]>(this.baseurl + 'api/Users/GetUsers', { params: params }).subscribe(result => {
      this.users = result;
    },
      error => {
        this.message = "Ismeretlen hiba történt!";
        this.success = false;
      });
  }

  changeOrder(o: string) {
    this.orderby = o;
    this.onSearch();
  }

  searchOpenClose() {
    if (this.searchOC)
      this.searchOC = false;
    else
      this.searchOC = true;
  }

  finishBorrow(id: number) {
    for (let i = 0; i < this.users.length; i++) {
      if (id === this.users[i].userId) {
        this.users[i].onDelete = true;
      }
    }
  }

  finishBorrowConfirm(id: number) {
    for (let i = 0; i < this.users.length; i++) {
      if (id === this.users[i].userId) {
        let params = new HttpParams().set('token', localStorage.getItem('jwt')).set('userId', this.users[i].userId.toString());
        this.httpService.get<boolean>(this.baseurl + 'api/Users/RemoveUser', { params: params }).subscribe(result => {

          let params = new HttpParams().set('token', localStorage.getItem('jwt')).set('fullName', this.searchFullName).set('group', this.searchGroup).set('orderby', this.orderby);
          this.httpService.get<User[]>(this.baseurl + 'api/Users/GetUsers', { params: params }).subscribe(result => {
            this.users = result;
          },
            error => {
              this.message = "Ismeretlen hiba történt!";
              this.success = false;
            });

        },
          error => {
            this.message = "Ismeretlen hiba történt!";
            this.success = false;
          });
      }
    }
  }

  removeUser(id: number) {
    for (let i = 0; i < this.users.length; i++) {
      if (id === this.users[i].userId) {
        this.users[i].onDelete = true;
      }
    }
  }

  deleteUser(id: number) {
    for (let i = 0; i < this.users.length; i++) {
      if (id === this.users[i].userId) {
        let params = new HttpParams().set('token', localStorage.getItem('jwt')).set('userId', this.users[i].userId.toString());
        this.httpService.get<boolean>(this.baseurl + 'api/Users/DeleteUser', { params: params }).subscribe(result => {
          if (result) {
            let params = new HttpParams().set('token', localStorage.getItem('jwt')).set('fullName', this.searchFullName).set('group', this.searchGroup).set('orderby', this.orderby);
            this.httpService.get<User[]>(this.baseurl + 'api/Users/GetUsers', { params: params }).subscribe(result => {
              this.users = result;
            },
              error => {
                this.message = "Ismeretlen hiba történt!";
                this.success = false;
              });
          }
        },
          error => {
            this.message = "Ismeretlen hiba történt!";
            this.success = false;
          });
      }
    }
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }
}

interface User {
  userId: number;
  loginName: string;
  fullName: string;
  password: string;
  birth: string;
  identifier: string;
  type: number;
  group: string;
  onDelete: boolean;
}
