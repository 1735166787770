import { Component, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import * as jwt_decode from "jwt-decode";

@Component({
  selector: 'app-borrows',
  templateUrl: './borrows.component.html',
})
export class BorrowsComponent {
  public baseurl: string;
  public items: Borrow[];
  public message: string;
  public success: boolean;
  public isAdmin: boolean;
  public searchOC: boolean;
  public searchState: string;
  public searchTitle: string;
  public searchAuthor: string;
  public searchUser: string;
  public searchIdentifier: string;
  public orderby: string;
  private sub: any;

  constructor(@Inject('BASE_URL') baseUrl: string, private httpService: HttpClient, private route: ActivatedRoute, private router: Router ) {
    this.baseurl = baseUrl;
    this.message = "";
    this.isAdmin = false;
    this.searchOC = false;

    this.searchState = "100";
    this.searchTitle = "";
    this.searchAuthor = "";
    this.searchUser = "";
    this.searchIdentifier = "";
    this.orderby = "titleasc";

    this.sub = this.route.params.subscribe(params => {
      if (params['status'] !== undefined) {
        this.searchState = params['status'];
        this.searchOC = true;
      }
    });

    let token = this.getDecodedAccessToken(localStorage.getItem('jwt'));
    for (let i = 0; i < token.roles.length; i++) {
      if (token.roles[i] === 'Admin')
        this.isAdmin = true;
    }

    let params = new HttpParams()
      .set('token', localStorage.getItem('jwt'))
      .set('title', this.searchTitle)
      .set('author', this.searchAuthor)
      .set('user', this.searchUser)
      .set('identifier', this.searchIdentifier)
      .set('state', this.searchState.toString())
      .set('orderby', this.orderby);
    this.httpService.get<Borrow[]>(this.baseurl + 'api/Borrows/GetBorrows', { params: params }).subscribe(result => {
      this.items = result;
    },
      error => {
        this.message = "Ismeretlen hiba történt!";
        this.success = false;
      });

  }

  changeOrder(o: string) {
    this.orderby = o;
    this.onSearch();
  }

  searchOpenClose() {
    if (this.searchOC)
      this.searchOC = false;
    else
      this.searchOC = true;
  }

  onSearch() {
    let params = new HttpParams()
      .set('token', localStorage.getItem('jwt'))
      .set('title', this.searchTitle)
      .set('author', this.searchAuthor)
      .set('user', this.searchUser)
      .set('identifier', this.searchIdentifier)
      .set('state', this.searchState.toString())
      .set('orderby', this.orderby);
    this.httpService.get<Borrow[]>(this.baseurl + 'api/Borrows/GetBorrows', { params: params }).subscribe(result => {
      this.items = result;
    },
      error => {
        this.message = "Ismeretlen hiba történt!";
        this.success = false;
      });
  }

  delayBorrow(id: number) {
    for (let i = 0; i < this.items.length; i++) {
      if (id === this.items[i].borrowId) {
        let params = new HttpParams().set('token', localStorage.getItem('jwt')).set('borrowId', this.items[i].borrowId.toString());
        this.httpService.get<boolean>(this.baseurl + 'api/Borrows/DelayBorrow', { params: params }).subscribe(result => {

          let params = new HttpParams()
            .set('token', localStorage.getItem('jwt'))
            .set('title', this.searchTitle)
            .set('author', this.searchAuthor)
            .set('user', this.searchUser)
            .set('identifier', this.searchIdentifier)
            .set('state', this.searchState.toString())
            .set('orderby', this.orderby);
          this.httpService.get<Borrow[]>(this.baseurl + 'api/Borrows/GetBorrows', { params: params }).subscribe(result => {
            this.items = result;
          },
            error => {
              this.message = "Ismeretlen hiba történt!";
              this.success = false;
            });

        },
          error => {
            this.message = "Ismeretlen hiba történt!";
            this.success = false;
          });
      }
    }
  }

  finishBorrow(id: number) {
    for (let i = 0; i < this.items.length; i++) {
      if (id === this.items[i].borrowId) {
        this.items[i].onFinish = true;
      }
    }
  }

  finishBorrowConfirm(id: number) {
    for (let i = 0; i < this.items.length; i++) {
      if (id === this.items[i].borrowId) {
        let params = new HttpParams().set('token', localStorage.getItem('jwt')).set('borrowId', this.items[i].borrowId.toString());
        this.httpService.get<boolean>(this.baseurl + 'api/Borrows/FinishBorrow', { params: params }).subscribe(result => {

          let params = new HttpParams()
            .set('token', localStorage.getItem('jwt'))
            .set('title', this.searchTitle)
            .set('author', this.searchAuthor)
            .set('user', this.searchUser)
            .set('identifier', this.searchIdentifier)
            .set('state', this.searchState.toString())
            .set('orderby', this.orderby);
          this.httpService.get<Borrow[]>(this.baseurl + 'api/Borrows/GetBorrows', { params: params }).subscribe(result => {
            this.items = result;
          },
            error => {
              this.message = "Ismeretlen hiba történt!";
              this.success = false;
            });

        },
          error => {
            this.message = "Ismeretlen hiba történt!";
            this.success = false;
          });
      }
    }
  }

  deleteBorrow(id: number) {
    for (let i = 0; i < this.items.length; i++) {
      if (id === this.items[i].borrowId) {
        this.items[i].onDelete = true;
      }
    }
  }

  deleteBorrowConfirm(id: number) {
    for (let i = 0; i < this.items.length; i++) {
      if (id === this.items[i].borrowId) {
        let params = new HttpParams().set('token', localStorage.getItem('jwt')).set('borrowId', this.items[i].borrowId.toString());
        this.httpService.get<boolean>(this.baseurl + 'api/Borrows/DeleteBorrow', { params: params }).subscribe(result => {

          let params = new HttpParams()
            .set('token', localStorage.getItem('jwt'))
            .set('title', this.searchTitle)
            .set('author', this.searchAuthor)
            .set('user', this.searchUser)
            .set('identifier', this.searchIdentifier)
            .set('state', this.searchState.toString())
            .set('orderby', this.orderby);
          this.httpService.get<Borrow[]>(this.baseurl + 'api/Borrows/GetBorrows', { params: params }).subscribe(result => {
            this.items = result;
          },
            error => {
              this.message = "Ismeretlen hiba történt!";
              this.success = false;
            });

        },
          error => {
            this.message = "Ismeretlen hiba történt!";
            this.success = false;
          });
      }
    }
  }

  onPreBorrowBorrow(id: number) {
    for (let i = 0; i < this.items.length; i++) {
      if (id === this.items[i].borrowId) {
        this.items[i].onPreBorrow = true;
      }
    }
  }

  onPreBorrowBorrowConfirm(id: number) {
    for (let i = 0; i < this.items.length; i++) {
      if (id === this.items[i].borrowId) {
        let params = new HttpParams().set('token', localStorage.getItem('jwt')).set('borrowId', this.items[i].borrowId.toString());
        this.httpService.get<boolean>(this.baseurl + 'api/Borrows/SaveBorrowForBookFromPreBorrow', { params: params }).subscribe(result => {

          let params = new HttpParams()
            .set('token', localStorage.getItem('jwt'))
            .set('title', this.searchTitle)
            .set('author', this.searchAuthor)
            .set('user', this.searchUser)
            .set('identifier', this.searchIdentifier)
            .set('state', this.searchState.toString())
            .set('orderby', this.orderby);
          this.httpService.get<Borrow[]>(this.baseurl + 'api/Borrows/GetBorrows', { params: params }).subscribe(result => {
            this.items = result;
          },
            error => {
              this.message = "Ismeretlen hiba történt!";
              this.success = false;
            });

        },
          error => {
            this.message = "Ismeretlen hiba történt!";
            this.success = false;
          });
      }
    }
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }
}

interface Borrow {
  borrowId: number;
  itemId: number;
  userId: number;
  copyId: number;
  title: string;
  author: string;
  cover: string;
  start: string;
  projectedEnd: string;
  status: number;
  userName: string;
  onFinish: boolean;
  onPreBorrow: boolean;
  onDelete: boolean;
}
